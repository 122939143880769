<template>
<!--  <TuiBanner />-->
  <TUIHeader/>
<!--  <h1 style="font-size: 32px;"> Voltanix, your favorite battery simulator </h1>-->
</template>

<script>
import TUIHeader from "@/components/TUIHeader.vue";
// import TuiBanner from "@/components/TuiBanner.vue";


export default {
  name: "Main-Header",
  components: {
    // TuiBanner,
    TUIHeader,
  }
}
</script>

<style scoped>

</style>