<template>
  <div class="flex flex-wrap gap-2 mt-4">
    <!-- Loop through the tags -->
    <div
        v-for="(tag, index) in tags"
        :key="index"
        class="flex items-center bg-indigo-50 text-indigo-700 text-sm font-medium px-3 py-1 rounded-full"
    >
      {{ tag }}
      <!-- XMarkIcon to remove the tag -->
      <XMarkIcon
          @click="removeTag(index)"
          class="ml-2 size-4 text-gray-500 hover:text-red-500 cursor-pointer"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

// Reactive tags array
const tags = ref(["app-v2.12", "priority-high", "model-test"]);

// Function to remove tag
const removeTag = (index) => {
  tags.value.splice(index, 1);
};
</script>
