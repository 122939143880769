<template>
  <Listbox as="div" v-model="selected">
    <ListboxLabel class="sr-only">Change published status</ListboxLabel>
    <div class="relative">
      <div class="inline-flex divide-x divide-indigo-200 rounded-md outline outline-1 outline-gray-300">
        <div class="inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-gray-600">
          <CheckIcon class="-ml-0.5 size-3" aria-hidden="true" />
          <p class="text-sm ">{{ selected.title }}</p>
        </div>
        <ListboxButton class="inline-flex items-center rounded-l-none rounded-r-md bg-white p-1 outline-none hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-700">
          <span class="sr-only">Change timer</span>
          <ChevronDownIcon class="size-4 bg-white text-gray-600 forced-colors:text-[Highlight]" aria-hidden="true" />
        </ListboxButton>
      </div>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute right-0 z-10 mt-0 w-80 origin-top-right divide-y divide-gray-600 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <ListboxOption as="template" v-for="option in timerOptions" :key="option.title" :value="option" v-slot="{ active, selected }">
            <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-400', 'cursor-default select-none p-1 text-sm']">
              <div class="flex flex-col">
                <div class="flex justify-between">
                  <p :class="selected ? 'font-semibold' : 'font-normal'">{{ option.title }}</p>
                  <span v-if="selected" :class="active ? 'text-white' : 'text-indigo-600'">
                    <CheckIcon class="size-2" aria-hidden="true" />
                  </span>
                </div>
                <p :class="[active ? 'text-indigo-200' : 'text-gray-600', 'mt-2']">{{ option.description }}</p>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { ref } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'

const timerOptions = [
  { title: 'Accelerated x10 v0.9', description: 'Ten times faster', current: false },
  { title: 'Real Time', description: 'Using real-time clock', current: true },
  { title: 'Time Freeze v0.3', description: 'Time change on command only', current: false },
]

const selected = ref(timerOptions[0])
</script>