<template>
  <div class="flex flex-wrap gap-2 mt-1">
    <!-- Loop through the tags -->
    <div
        v-for="(alert, index) in alerts"
        :key="index"
        class="flex items-center bg-indigo-50 text-indigo-700 text-sm font-medium px-3 py-1 rounded-full"
    >
      <!-- Badge Styling for ":" -->
      <span
        class="bg-indigo-200 text-indigo-900 font-light px-2 py-0.5 rounded-lg mr-2"
      >
        {{ getPrefix(alert) }}
      </span>
      <!-- Remaining Text -->
      <span>{{ getValue(alert) }}</span>

      <!-- XMarkIcon to remove the market -->
      <XMarkIcon
          @click="removeTag(index)"
          class="ml-2 size-4 text-gray-500 hover:text-red-500 cursor-pointer"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

// Reactive tags array
const alerts = ref(["alert-quality-v0.92", "alert-devops-high-v0.85"]);

// Function to remove tag
const removeTag = (index) => {
  alerts.value.splice(index, 1);
};

function hasPrefix(alert) {
  return alert.indexOf(":") >= 0;
}
function getPrefix(alert) {
  if (!hasPrefix(alert)){
    return ''
  }
  return alert.split(':')[0]
}
function getValue(alert) {
  if (!hasPrefix(alert)){
    return alert
  }
  return alert.split(':')[1]
}
</script>
