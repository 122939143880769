<template>
  <div class="flex flex-wrap gap-2 mt-1">
    <!-- Loop through the tags -->
    <div
        v-for="(market, index) in markets"
        :key="index"
        class="flex items-center bg-indigo-50 text-indigo-700 text-sm font-medium px-3 py-1 rounded-full"
    >
      <!-- Badge Styling for "fcr:" and "afrr:" -->
      <span
        class="bg-indigo-200 text-indigo-900 font-light px-2 py-0.5 rounded-lg mr-2"
      >
        {{ market.split(':')[0] }}
      </span>
      <!-- Remaining Text -->
      <span>{{ market.split(':')[1].trim() }}</span>

      <!-- XMarkIcon to remove the market -->
      <XMarkIcon
          @click="removeTag(index)"
          class="ml-2 size-4 text-gray-500 hover:text-red-500 cursor-pointer"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

// Reactive tags array
const markets = ref(["fcr: fcr-const-5MW-v0.9", "afrr: afrr-24H-var-v0.65"]);

// Function to remove tag
const removeTag = (index) => {
  markets.value.splice(index, 1);
};

</script>
