<template>
  <!-- Feature section -->
  <div class="mx-auto mt-4 max-w-7xl px-6 sm:mt-16 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <h2 class="text-base/7 font-semibold text-indigo-600">Advance faster</h2>
      <p class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
        Optimize Battery Usage and Lifespan </p>
      <p class="mt-6 text-lg/8 text-gray-600"> Understand Your Battery's Behavior</p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div v-for="feature in secondaryFeatures" :key="feature.name" class="flex flex-col">
          <dt class="flex items-center gap-x-3 text-base/7 font-semibold text-gray-900">
            <component :is="feature.icon" class="size-5 flex-none text-indigo-600" aria-hidden="true" />
            {{ feature.name }}
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base/7 text-gray-600">
            <p class="flex-auto">{{ feature.description }}</p>
            <p class="mt-6">
              <!--                  <a :href="feature.href" class="text-sm/6 font-semibold text-indigo-600">Learn more <span aria-hidden="true">→</span></a>-->
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import {ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon,ClockIcon} from "@heroicons/vue/20/solid";
const secondaryFeatures = [
    {
      name: 'Profile simulation',
      description:
          'Simulate charging profiles and analyze charging efficiency.',
      href: '#',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Cycling Strategy',
      description:
          'Model battery degradation over multiple charge-discharge cycles. Develop charging and discharge schedules to prolong battery life and maximize performance.',
      href: '#',
      icon: LockClosedIcon,
    },
    {
      name: 'Storage',
      description:
          'Assess the impact of storage conditions on battery performance and lifespan.',
      href: '#',
      icon: ArrowPathIcon,
    },
    {
      name: 'Charge Management',
      description:
          'Determine the optimal charge rate and depth of discharge for maximizing lifespan.',

      href: '#',
      icon: ArrowPathIcon,
    },
    {
      name: '🌡Temperature Control',
      description:
          'Optimize battery temperature to mitigate degradation and improve performance. ' +
          'Model how temperature influences battery performance, including capacity, discharge rate, and lifespan.',
      href: '#',
      icon: '',
    },
    {
      name: ' 🪫 State of Charge (SOC)',
      description: 'Track the battery\'s remaining capacity and predict its lifespan based on usage patterns.',
      href: '#',
      icon: '',
    },
    {
      name: 'Discharge Rate',
      description: 'Analyze how the battery\'s voltage and capacity change over time under different discharge rates.',
      href: '#',
      icon: ArrowPathIcon,
    },
    {
      name: 'Time Control',
      description: 'Get faster test results with Time Control, managing elapsed time',
      href: '#',
      icon: ClockIcon,
    },
  ]

</script>

<style scoped>

</style>