<template>
  <div> footer 232 </div>
</template>

<script>
export default {
  name: "Main-Footer"
}
</script>

<style scoped>

</style>