<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        <div class="col-span-2">
          <h2 class="text-base/7 font-semibold text-indigo-600">Everything you need</h2>
          <p class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">All-in-one platform</p>
          <p class="mt-6 text-base/7 text-gray-600">One place to get all your answers</p>
        </div>
        <dl class="col-span-3 grid grid-cols-1 gap-x-8 gap-y-10 text-base/7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
          <div v-for="feature in features" :key="feature.name" class="relative pl-9">
            <dt class="font-semibold text-gray-900">
              <CheckIcon class="absolute left-0 top-1 size-5 text-indigo-500" aria-hidden="true" />
              {{ feature.name }}
            </dt>
            <dd class="mt-2">{{ feature.description }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon } from '@heroicons/vue/20/solid'

const features = [
  {
    name: 'High accuracy',
    description: 'Leverage real-world data to get simulation results with unprecedented accuracy.',
  },
  { name: 'Customizable models', description: 'Use the simulation model & tailor the simulation parameters to fit your specific use case and battery chemistry.' },
  {
    name: 'Real-time',
    description: 'Get live updates on battery status, projected lifespans, and performance analytics.',
  },
  {
    name: 'Optimize',
    description: 'Implement our personalized recommendations.',
  },
  {name:'Intuitive interface for easy exploration', description:'Easily customize simulation parameters. Explore data visualizations in real-time. Navigate effortlessly through the simulation environment and access key features with ease.'},

  {name:'Integrations', description:'Connect to your favorite 3rd-party tools.'},
  {name:'Analytics', description:'Data & insights. No blind decisions, back it up with numbers and visualizations.'},

]
</script>