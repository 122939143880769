<template>
<!--  <div> main content </div>-->
<!--  <TUIFlyoutMenu />-->
<!--  <TuiBanner />-->
<!--  <AlertExample/>-->
<!--  <NotificationExample/>-->
<!--  <ModalDialogExample/>-->
<!--  <NotificationWithAvatarExample/>-->
<!--  <TuiCardHeadingExample/>-->
<!--  <HeadingWithWatchAndDropDownExample/>-->

  <RunSimulationForm/>
  <TuiBetterWayToShip/>
  <!--  <OffsetWithFeatureList3columns />-->
<!--  <TuiPrivacyNotice />-->
<!--  <TuiLandingPage />-->
<!--  <TuiPricingThatGrowsWithYou/>-->
<!--  <TuiAboutUsPage/>-->
<!--  <SectionHeadingExample/>-->
<!--  <FeedExample/>-->

</template>

<script>
// import TUIFlyoutMenu from "@/components/TUIFlyoutMenu.vue";
// import TuiPrivacyNotice from "@/components/TuiPrivacyNotice.vue";
// import TuiLandingPage from "@/components/TuiLandingPage.vue";
import TuiBetterWayToShip from "@/components/TuiBetterWayToShip.vue";
import RunSimulationForm from "@/components/RunSimulationForm.vue";
// import OffsetWithFeatureList3columns from "@/components/OffsetWithFeatureList-3columns.vue";
// import TuiPricingThatGrowsWithYou from "@/components/TuiPricingThatGrowsWithYou.vue";
// import TuiAboutUsPage from "@/components/TuiAboutUsPage.vue";
// import TuiCardHeadingExample from "@/components/TuiCardHeadingExample.vue";
// import SectionHeadingExample from "@/components/SectionHeadingExample.vue";
// import HeadingWithWatchAndDropDownExample from "@/components/HeadingWithWatchAndDropDownExample.vue";
// import AlertExample from "@/components/AlertExample.vue";
// import ModalDialogExample from "@/components/ModalDialogExample.vue";
// import NotificationExample from "@/components/NotificationExample.vue";
// import NotificationWithAvatarExample from "@/components/NotificationWithAvatarExample.vue";
// import FeedExample from "@/components/FeedExample.vue";

export default {
  name: "Main-Content",
  components: {
    // OffsetWithFeatureList3columns,
    // NotificationWithAvatarExample,
    // NotificationExample,
    // ModalDialogExample,
    // AlertExample,
    // FeedExample,
    // HeadingWithWatchAndDropDownExample,
    // SectionHeadingExample,
    // TuiCardHeadingExample,
    // TuiAboutUsPage,
    // TuiPricingThatGrowsWithYou,
    TuiBetterWayToShip,
    RunSimulationForm,
    // TuiLandingPage,
    // TuiPrivacyNotice,
  }
}
</script>

<style scoped>

</style>