<template>
  <div class="bg-white">
    <!-- Header -->
<!--    <header class="absolute inset-x-0 top-0 z-50">-->
<!--      <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">-->
<!--        <div class="flex lg:flex-1">-->
<!--          <a href="#" class="-m-1.5 p-1.5">-->
<!--            <span class="sr-only">Your Company</span>-->
<!--            <img class="h-8 w-auto" src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600" alt="" />-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="flex lg:hidden">-->
<!--          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">-->
<!--            <span class="sr-only">Open main menu</span>-->
<!--            <Bars3Icon class="size-6" aria-hidden="true" />-->
<!--          </button>-->
<!--        </div>-->
<!--        <div class="hidden lg:flex lg:gap-x-12">-->
<!--          <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-sm/6 font-semibold text-gray-900">{{ item.name }}</a>-->
<!--        </div>-->
<!--        <div class="hidden lg:flex lg:flex-1 lg:justify-end">-->
<!--          <a href="#" class="text-sm/6 font-semibold text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a>-->
<!--        </div>-->
<!--      </nav>-->
<!--      <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">-->
<!--        <div class="fixed inset-0 z-50" />-->
<!--        <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">-->
<!--          <div class="flex items-center justify-between">-->
<!--            <a href="#" class="-m-1.5 p-1.5">-->
<!--              <span class="sr-only">Your Company</span>-->
<!--              <img class="h-8 w-auto" src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600" alt="" />-->
<!--            </a>-->
<!--            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">-->
<!--              <span class="sr-only">Close menu</span>-->
<!--              <XMarkIcon class="size-6" aria-hidden="true" />-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="mt-6 flow-root">-->
<!--            <div class="-my-6 divide-y divide-gray-500/10">-->
<!--              <div class="space-y-2 py-6">-->
<!--                <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">{{ item.name }}</a>-->
<!--              </div>-->
<!--              <div class="py-6">-->
<!--                <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Log in</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </DialogPanel>-->
<!--      </Dialog>-->
<!--    </header>-->

    <main>
      <!-- Hero section -->
      <div class="relative isolate pt-10">
        <svg class="absolute inset-0 -z-10 size-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
          <defs>
            <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
            <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
<!--            <div class="flex">-->
<!--              <div class="relative flex items-center gap-x-4 rounded-full bg-white px-4 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">-->
<!--                <span class="font-semibold text-indigo-600">We’re hiring</span>-->
<!--                <span class="h-4 w-px bg-gray-900/10" aria-hidden="true" />-->
<!--                <a href="#" class="flex items-center gap-x-1">-->
<!--                  <span class="absolute inset-0" aria-hidden="true" />-->
<!--                  See open positions-->
<!--                  <ChevronRightIcon class="-mr-2 size-5 text-gray-400" aria-hidden="true" />-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->
            <h1 class=" -mt-12 sm:-mt-16 lg:-mt-96 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
              Voltanix, your favorite battery simulator </h1>

<!--            <h2> Understand battery behaviour and optimize performance </h2>-->
            <p class="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
              Voltanix is a powerful battery simulation software designed to help engineers, researchers,
              and anyone interested in battery technology understand battery behavior and optimize performance.
            </p>
            <p class="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
              Supercharge your battery development with cutting-edge simulations
              powered by Voltanix’s advanced AI engine
            </p>
            <div class="mt-10 flex items-center gap-x-6">
<!--              <a href="#" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>-->
<!--              <a href="#" class="text-sm/6 font-semibold text-gray-900"> Learn more <span aria-hidden="true">→</span></a>-->
            </div>
          </div>
          <div class="mt-12 sm:mt-24 lg:mt-0 lg:shrink-0 lg:grow">
            <svg viewBox="0 0 366 729" role="img" class="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width="316" height="684" rx="36" />
                </clipPath>
              </defs>
              <path fill="#4B5563" d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z" />
              <path fill="#343E4E" d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z" />
              <foreignObject width="316" height="684" transform="translate(24 24)" clip-path="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)">
<!--                <img src="https://tailwindui.com/plus/img/component-images/mobile-app-screenshot.png" alt="" />-->
                <img src="@/assets/voltanix-screenshot-v0.44.png" alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>


      <UnderstandingBehaviour class="mb-16"/>


      <!-- Logo cloud -->
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/transistor-logo-gray-400.svg" alt="Transistor" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/reform-logo-gray-400.svg" alt="Reform" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/tuple-logo-gray-400.svg" alt="Tuple" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/savvycal-logo-gray-400.svg" alt="SavvyCal" width="158" height="48" />
          <img class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://tailwindui.com/plus/img/logos/158x48/statamic-logo-gray-400.svg" alt="Statamic" width="158" height="48" />
        </div>
      </div>

      <ProductScreenshotPanel class="mt-32"/>
      <OffsetWithFeatureList3columns />

      <!-- Feature section -->
<!--      <div class="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">-->
<!--        <div class="relative isolate overflow-hidden bg-gray-900 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">-->
<!--          <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">-->
<!--            <div class="lg:row-start-2 lg:max-w-md">-->
<!--              <h2 class="text-balance text-3xl font-semibold tracking-tight text-white sm:text-4xl">-->
<!--                Scenarios that didn't happen - yet.-->
<!--              </h2>-->
<!--              <p class="mt-6 text-lg/8 text-gray-300">-->
<!--                Proactive. <br>-->
<!--                Preventive. <br>-->
<!--                Visual. <br>-->
<!--              </p>-->
<!--            </div>-->
<!--            <img src="@/assets/graphic-design-v0.11.webp" alt="Product screenshot" class="relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none" width="2432" height="1442" />-->
<!--            <div class="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">-->
<!--              <dl class="max-w-xl space-y-8 text-base/7 text-gray-300 lg:max-w-none">-->
<!--                <div v-for="feature in primaryFeatures" :key="feature.name" class="relative">-->
<!--                  <dt class="ml-9 inline-block font-semibold text-white">-->
<!--                    <component :is="feature.icon" class="absolute left-1 top-1 size-5 text-indigo-500" aria-hidden="true" />-->
<!--                    {{ feature.name }}-->
<!--                  </dt>-->
<!--                  {{ ' ' }}-->
<!--                  <dd class="inline">{{ feature.description }}</dd>-->
<!--                </div>-->
<!--              </dl>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu" aria-hidden="true">-->
<!--            <div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


      <GraphicCapabilities />




      <!-- Request demo -->
      <div class="mx-auto mt-10 max-w-7xl sm:mt-32 sm:px-6 lg:px-8">
        <div class="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
          <h2 class="mx-auto max-w-3xl text-center text-3xl font-semibold tracking-tight text-white sm:text-4xl">
            Want to Start with Voltanix Today ? </h2>
          <p class="mx-auto mt-6 max-w-lg text-center text-2xl text-gray-300"> Get ahead - Request pre-launch demo </p>
          <form class="mx-auto mt-10 flex max-w-md gap-x-4">
            <!--            <label for="email-address" class="sr-only">Email address</label>-->
            <!--            <input id="email-address" name="email" type="email" autocomplete="email" required="" class="min-w-0 flex-auto rounded-md bg-white/5 px-3.5 py-2 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6" placeholder="Enter your email" />-->
            <button type="submit" class="mx-auto rounded-md bg-blue-300 px-6 py-4 text-3xl font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"> Request Demo </button>
          </form>
          <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2" aria-hidden="true">
            <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(512 512) rotate(90) scale(512)">
                <stop stop-color="#7775D6" />
                <stop offset="1" stop-color="#E935C1" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>


          <!-- Newsletter section -->
            <div class="relative isolate mt-16 overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-8">
              <h2 class="mx-auto max-w-3xl text-center text-2xl font-semibold tracking-tight text-white sm:text-3xl"> Or prefer to get news from us ? </h2>
              <p class="mx-auto mt-6 max-w-lg text-center text-lg text-gray-300">
                No spam. <br> One email / month max.
              </p>
              <form name="email-signup" method="POST" class="mx-auto mt-10 flex max-w-md gap-x-4" data-netlify="true" data-netlify-honeypot="bot-field">
<!--                    <input type="hidden" name="form-name" value="email-signup">-->
                <label for="email-address" class="sr-only">Email address</label>
                <input id="email-address" name="email" type="email" autocomplete="email" required class="min-w-0 flex-auto rounded-md bg-white/5 px-3.5 py-2 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6" placeholder="Enter your email" />
                <button name="submit-button" type="submit" class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Yes, keep me informed</button>
              </form>
              <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2" aria-hidden="true">
                <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
                <defs>
                  <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(512 512) rotate(90) scale(512)">
                    <stop stop-color="#7775D6" />
                    <stop offset="1" stop-color="#E935C1" stop-opacity="0" />
                  </radialGradient>
                </defs>
              </svg>
            </div>

        </div>
      </div>







      <!-- Testimonials section -->
<!--      <div class="relative isolate mt-32 sm:mt-56 sm:pt-32">-->
<!--        <svg class="absolute inset-0 -z-10 hidden size-full stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)] sm:block" aria-hidden="true">-->
<!--          <defs>-->
<!--            <pattern id="55d3d46d-692e-45f2-becd-d8bdc9344f45" width="200" height="200" x="50%" y="0" patternUnits="userSpaceOnUse">-->
<!--              <path d="M.5 200V.5H200" fill="none" />-->
<!--            </pattern>-->
<!--          </defs>-->
<!--          <svg x="50%" y="0" class="overflow-visible fill-gray-50">-->
<!--            <path d="M-200.5 0h201v201h-201Z M599.5 0h201v201h-201Z M399.5 400h201v201h-201Z M-400.5 600h201v201h-201Z" stroke-width="0" />-->
<!--          </svg>-->
<!--          <rect width="100%" height="100%" stroke-width="0" fill="url(#55d3d46d-692e-45f2-becd-d8bdc9344f45)" />-->
<!--        </svg>-->
<!--        <div class="relative">-->
<!--          <div class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl" aria-hidden="true">-->
<!--            <div class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />-->
<!--          </div>-->
<!--          <div class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-8 opacity-25 blur-3xl xl:justify-end" aria-hidden="true">-->
<!--            <div class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />-->
<!--          </div>-->
<!--          <div class="mx-auto max-w-7xl px-6 lg:px-8">-->
<!--            <div class="mx-auto max-w-2xl sm:text-center">-->
<!--              <h2 class="text-base/7 font-semibold text-indigo-600">Testimonials</h2>-->
<!--              <p class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-5xl">We have worked with thousands of amazing people</p>-->
<!--            </div>-->
<!--            <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm/6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">-->
<!--              <figure class="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">-->
<!--                <blockquote class="p-12 text-xl/8 font-semibold tracking-tight text-gray-900">-->
<!--                  <p>{{ `“${featuredTestimonial.body}”` }}</p>-->
<!--                </blockquote>-->
<!--                <figcaption class="flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4">-->
<!--                  <img class="size-10 flex-none rounded-full bg-gray-50" :src="featuredTestimonial.author.imageUrl" alt="" />-->
<!--                  <div class="flex-auto">-->
<!--                    <div class="font-semibold">{{ featuredTestimonial.author.name }}</div>-->
<!--                    <div class="text-gray-600">{{ `@${featuredTestimonial.author.handle}` }}</div>-->
<!--                  </div>-->
<!--                  <img class="h-10 w-auto flex-none" :src="featuredTestimonial.author.logoUrl" alt="" />-->
<!--                </figcaption>-->
<!--              </figure>-->
<!--              <div v-for="(columnGroup, columnGroupIdx) in testimonials" :key="columnGroupIdx" class="space-y-8 xl:contents xl:space-y-0">-->
<!--                <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx" :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">-->
<!--                  <figure v-for="testimonial in column" :key="testimonial.author.handle" class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">-->
<!--                    <blockquote class="text-gray-900">-->
<!--                      <p>{{ `“${testimonial.body}”` }}</p>-->
<!--                    </blockquote>-->
<!--                    <figcaption class="mt-6 flex items-center gap-x-4">-->
<!--                      <img class="size-10 rounded-full bg-gray-50" :src="testimonial.author.imageUrl" alt="" />-->
<!--                      <div>-->
<!--                        <div class="font-semibold">{{ testimonial.author.name }}</div>-->
<!--                        <div class="text-gray-600">{{ `@${testimonial.author.handle}` }}</div>-->
<!--                      </div>-->
<!--                    </figcaption>-->
<!--                  </figure>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </main>

    <!-- Footer -->
    <footer class="mt-32 bg-gray-900 sm:mt-56">
      <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
          <img class="h-16 rounded-lg p-1 invert rever brightness-200" src="@/assets/voltanix-logo-v0.2.svg" alt="Company name" />
          <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm/6 font-semibold text-white">Solutions</h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li v-for="item in footerNavigation.solutions" :key="item.name">
<!--                    <a :href="item.href" class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>-->
                    <a class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>
                  </li>
                </ul>
              </div>
              <div class="mt-10 md:mt-0">
                <h3 class="text-sm/6 font-semibold text-white">Support</h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li v-for="item in footerNavigation.support" :key="item.name">
                    <a class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>
<!--                    <a :href="item.href" class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>-->
                  </li>
                </ul>
              </div>
            </div>
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm/6 font-semibold text-white">Company</h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li v-for="item in footerNavigation.company" :key="item.name">
                    <a class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>
<!--                    <a :href="item.href" class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>-->
                  </li>
                </ul>
              </div>
              <div class="mt-10 md:mt-0">
                <h3 class="text-sm/6 font-semibold text-white">Legal</h3>
                <ul role="list" class="mt-6 space-y-4">
                  <li v-for="item in footerNavigation.legal" :key="item.name">
<!--                    <a :href="item.href" class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>-->
                    <a class="text-sm/6 text-gray-400 hover:text-white">{{ item.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">-->
<!--          <div>-->
<!--            <h3 class="text-sm/6 font-semibold text-white">Subscribe to our newsletter</h3>-->
<!--            <p class="mt-2 text-sm/6 text-gray-300">The latest news, articles, and resources, sent to your inbox weekly.</p>-->
<!--          </div>-->
<!--          <form class="mt-6 sm:flex sm:max-w-md lg:mt-0">-->
<!--            <label for="email-address" class="sr-only">Email address</label>-->
<!--            <input type="email" name="email-address" id="email-address" autocomplete="email" required="" class="w-full min-w-0 rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:w-56 sm:text-sm/6" placeholder="Enter your email" />-->
<!--            <div class="mt-4 sm:ml-4 sm:mt-0 sm:shrink-0">-->
<!--              <button type="submit" class="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Subscribe</button>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
        <div class="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
          <div class="flex gap-x-6 md:order-2">
            <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-300">
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="size-6" aria-hidden="true" />
            </a>
          </div>
          <p class="mt-8 text-sm/6 text-gray-400 md:order-1 md:mt-0">&copy; 2024-2025 Voltanix S.A.S.U.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { defineComponent, h, ref } from 'vue'
// import { Dialog, DialogPanel } from '@headlessui/vue'
// import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import {
  // ChevronRightIcon,
  /*CloudArrowUpIcon, LockClosedIcon,  ServerIcon*/ } from '@heroicons/vue/20/solid'
import OffsetWithFeatureList3columns from "@/components/OffsetWithFeatureList-3columns.vue";
import UnderstandingBehaviour from '@/components/UnderstandingBehaviour.vue';
import ProductScreenshotPanel from "@/components/ProductScreenshotPanel.vue";
import GraphicCapabilities from "@/components/GraphicCapabilities.vue";

// const navigation = [
//   { name: 'BWTS Product', href: '#' },
//   { name: 'Features', href: '#' },
//   { name: 'Marketplace', href: '#' },
//   { name: 'Company', href: '#' },
// ]
// const primaryFeatures = [
  // {
  //   name: 'Discharge Rate',
  //   description: 'Analyze how the battery\'s voltage and capacity change over time under different discharge rates.',
  //   icon: CloudArrowUpIcon,
  // },
  // {
  //   name: 'Temperature Impact',
  //   description: 'Model how temperature influences battery performance, including capacity, discharge rate, and lifespan.',
  //   icon: LockClosedIcon,
  // },
  // {
  //   name: 'State of Charge (SOC)',
  //   description: 'Track the battery\'s remaining capacity and predict its lifespan based on usage patterns.',
  //   icon: ServerIcon,
  // },
  // {
  //   name: 'Discharge Rate',
  //   description: 'Analyze how the battery\'s voltage and capacity change over time under different discharge rates.',
  //   icon: ServerIcon,
  // },
// ]

// const featuredTestimonial = {
//   body: 'Integer id nunc sit semper purus. Bibendum at lacus ut arcu blandit montes vitae auctor libero. Hac condimentum dignissim nibh vulputate ut nunc. Amet nibh orci mi venenatis blandit vel et proin. Non hendrerit in vel ac diam.',
//   author: {
//     name: 'Brenna Goyette',
//     handle: 'brennagoyette',
//     imageUrl:
//         'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=1024&h=1024&q=80',
//     logoUrl: 'https://tailwindui.com/plus/img/logos/savvycal-logo-gray-900.svg',
//   },
// }
// const testimonials = [
//   [
//     [
//       {
//         body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
//         author: {
//           name: 'Leslie Alexander',
//           handle: 'lesliealexander',
//           imageUrl:
//               'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//         },
//       },
//       // More testimonials...
//     ],
//     [
//       {
//         body: 'Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.',
//         author: {
//           name: 'Lindsay Walton',
//           handle: 'lindsaywalton',
//           imageUrl:
//               'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//         },
//       },
//       // More testimonials...
//     ],
//   ],
//   [
//     [
//       {
//         body: 'Voluptas quos itaque ipsam in voluptatem est. Iste eos blanditiis repudiandae. Earum deserunt enim molestiae ipsum perferendis recusandae saepe corrupti.',
//         author: {
//           name: 'Tom Cook',
//           handle: 'tomcook',
//           imageUrl:
//               'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//         },
//       },
//       // More testimonials...
//     ],
//     [
//       {
//         body: 'Molestias ea earum quos nostrum doloremque sed. Quaerat quasi aut velit incidunt excepturi rerum voluptatem minus harum.',
//         author: {
//           name: 'Leonard Krasner',
//           handle: 'leonardkrasner',
//           imageUrl:
//               'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//         },
//       },
//       // More testimonials...
//     ],
//   ],
// ]
const footerNavigation = {
  solutions: [
    { name: 'Marketing', href: '#marketing' },
    { name: 'Analytics', href: '#' },
    { name: 'Automation', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Submit ticket', href: '/submit-ticket' },
    { name: 'Documentation', href: '/docs' },
    { name: 'Guides', href: '/guides' },
  ],
  company: [
    { name: 'About', href: '/company' },
    { name: 'Our values', href: '/our-values' },
    // { name: 'Jobs', href: '#' },
    // { name: 'Press', href: '#' },
  ],
  legal: [
    { name: 'Terms of service', href: '#' },
    { name: 'Privacy policy', href: '#' },
    { name: 'License', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
    {
      name: 'X',
      href: '#',
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                d: 'M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z',
              }),
            ]),
      }),
    },
    {
      name: 'GitHub',
      href: '#',
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: defineComponent({
        render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
                'clip-rule': 'evenodd',
              }),
            ]),
      }),
    },
  ],
}

const mobileMenuOpen = ref(false)
console.log(mobileMenuOpen);
</script>